<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <b-col lg="12">
          <b-overlay
            :show="ShowRound"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-card>
              <b-row>
                <b-col lg="6">
                  <h3>เลือกหวย</h3>
                </b-col>
              </b-row>

              <b-form-group
                label="หวย"
                label-for="customDelimiter"
              >
                <v-select
                  v-model="LottoHead"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="LottoList"
                  :reduce="ele => ele.value"
                  @input="LottoSubHead = null ,FindSublist(LottoHead) ,SelectType()"
                />

              </b-form-group>
              <b-form-group
                v-if="LottoSubList"
                label="ประเภทหวย"
                label-for="customDelimiter"
              >
                <v-select
                  v-model="LottoSubHead"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="LottoSubList"
                  :reduce="ele => ele.value"
                  @input="SelectType()"
                />
              </b-form-group>
              <b-row>
                <b-col
                  v-if="RoundData.length > 0"
                  lg="12"
                >
                  <b-form-group
                    label="งวดประจำวันที่"
                    label-for="customDelimiter"
                  >
                    <v-select
                      v-model="RoundID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="CloseDateName"
                      :options="RoundData"
                      :reduce="ele => ele._id"
                      @input="SelectRound()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-else-if="(LottoHead && !LottoSubList) || LottoSubHead"
                  lg="12"
                >
                  <div class="alert alert-primary p-1 text-center">
                    <i class="fa fa-folder-open" /> ไม่พบรอบหวย
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-overlay>
        </b-col>

        <b-col lg="12">
          <b-overlay
            :show="ShowResult"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-row class="match-height">
              <b-col
                v-if="((LottoHead && !LottoSubList) || LottoSubHead) && RoundID"
                lg="12"
              >
                <b-card>
                  สถานะ
                  <div class="text-center">
                    <h3 class="font-weight-bolder">
                      {{ ResultData.status === 'error' ? 'ออกรางวัลไม่สำเร็จ' : ResultData.status === 'processing' ? 'กำลังตรวจสอบ' :ResultData.status === 'reward' ? 'กำลังออกรางวัล' : ResultData.status === 'success' ? 'ออกรางวัลสำเร็จ' : ResultData.status === 'waiting_approve' ? 'รอยืนยัน' : 'รอออกรางวัล' }}
                    </h3>
                    <div
                      v-if="ResultData.status === 'processing'"
                      class="progress-wrapper"
                    >
                      <b-card-text class="mb-0">
                        {{ TotalPro }}
                        กำลังตรวจสอบ… {{ Calpercen(TotalPro,TotalBet)+'%' }}
                      </b-card-text>
                      <b-progress
                        v-model="TotalPro"
                        :max="TotalBet"
                      />
                    </div>
                  </div>
                </b-card>
              </b-col>

              <b-col
                v-if="((LottoHead && !LottoSubList) || LottoSubHead) && ResultData.status"
                lg="12"
              >
                <b-row
                  v-if="ResultData.status !== 'waiting_approve' && ResultData.status !== 'waiting' && ResultData.status !== 'processing'"
                  class="match-height"
                >
                  <b-col lg="4">
                    <b-card>
                      <div class="text-center">
                        <h3>จำนวนรายการแทง</h3>
                        <h3 class="font-weight-bolder">
                          {{ Commas(TotalReward) }}
                          <small>รายการ</small>
                        </h3>
                        <p>3 ตัวบน : {{ Commas(ResultData.Total.U3) }} <small>รายการ</small></p>
                        <p>3 ตัวหน้า : {{ Commas(ResultData.Total.D3) }} <small>รายการ</small></p>
                        <p>3 ตัวท้าย : {{ Commas(ResultData.Total.B3 ? ResultData.Total.B3 : 0) }} <small>รายการ</small></p>
                        <p>3 ตัวโต๊ด : {{ Commas(ResultData.Total.T3) }} <small>รายการ</small></p>
                        <p>2 ตัวบน : {{ Commas(ResultData.Total.U2) }} <small>รายการ</small></p>
                        <p>2 ตัวล่าง : {{ Commas(ResultData.Total.D2) }} <small>รายการ</small></p>
                        <p>วิ่งบน : {{ Commas(ResultData.Total.U1) }} <small>รายการ</small></p>
                        <p>วิ่งล่าง : {{ Commas(ResultData.Total.D1) }} <small>รายการ</small></p>
                        <p>ยอดรวม : {{ Commas(SumAmountALl) }} <small>บาท</small></p>
                      </div>
                    </b-card>
                  </b-col>

                  <b-col lg="4">
                    <b-card>
                      <div class="text-center">
                        <h3>จำนวนแทงถูก</h3>
                        <h3 class="font-weight-bolder">
                          {{ Commas(TotalWin) }}
                          <small>รายการ</small>
                        </h3>
                        <p>3 ตัวบน : {{ Commas(ResultData.WinTotal.U3) }} <small>รายการ</small></p>
                        <p>3 ตัวหน้า : {{ Commas(ResultData.WinTotal.D3) }} <small>รายการ</small></p>
                        <p>3 ตัวท้าย : {{ Commas(ResultData.WinTotal.B3 ? ResultData.WinTotal.B3 : 0) }} <small>รายการ</small></p>
                        <p>3 ตัวโต๊ด : {{ Commas(ResultData.WinTotal.T3) }} <small>รายการ</small></p>
                        <p>2 ตัวบน : {{ Commas(ResultData.WinTotal.U2) }} <small>รายการ</small></p>
                        <p>2 ตัวล่าง : {{ Commas(ResultData.WinTotal.D2) }} <small>รายการ</small></p>
                        <p>วิ่งบน : {{ Commas(ResultData.WinTotal.U1) }} <small>รายการ</small></p>
                        <p>วิ่งล่าง : {{ Commas(ResultData.WinTotal.D1) }} <small>รายการ</small></p>
                        <p>ยอดรวม : {{ Commas(SumAmountWin) }} <small>บาท</small></p>
                      </div>
                    </b-card>
                  </b-col>

                  <b-col lg="4">
                    <b-card>
                      <div class="text-center">
                        <h3>จำนวนแทงเสีย</h3>
                        <h3 class="font-weight-bolder">
                          {{ Commas(TotalLose) }}
                          <small>รายการ</small>
                        </h3>
                        <p>3 ตัวบน : {{ Commas(ResultData.LoseTotal.U3) }} <small>รายการ</small></p>
                        <p>3 ตัวหน้า : {{ Commas(ResultData.LoseTotal.D3) }} <small>รายการ</small></p>
                        <p>3 ตัวท้าย: {{ Commas(ResultData.LoseTotal.B3 ? ResultData.LoseTotal.B3 : 0) }} <small>รายการ</small></p>
                        <p>3 ตัวโต๊ด : {{ Commas(ResultData.LoseTotal.T3) }} <small>รายการ</small></p>
                        <p>2 ตัวบน : {{ Commas(ResultData.LoseTotal.U2) }} <small>รายการ</small></p>
                        <p>2 ตัวล่าง : {{ Commas(ResultData.LoseTotal.D2) }} <small>รายการ</small></p>
                        <p>วิ่งบน : {{ Commas(ResultData.LoseTotal.U1) }} <small>รายการ</small></p>
                        <p>วิ่งล่าง : {{ Commas(ResultData.LoseTotal.D1) }} <small>รายการ</small></p>
                      </div>
                    </b-card>
                  </b-col>
                  <!-- <b-col lg="3">
                    <b-card>
                      ตรวจโพยถูกหวย
                      <div class="text-center">
                        <h3 class="font-weight-bolder">
                          {{ Commas(TotalLog) }}
                          <small>รายการ</small>
                        </h3>
                      </div>
                      <div class="progress-wrapper">
                        <b-card-text class="mb-0">
                          กำลังตรวจสอบ… {{ TotalWin === 0 ? 0 :Calpercen(TotalLog,TotalWin)+'%' }}
                        </b-card-text>
                        <b-progress
                          v-model="TotalLog"
                          :max="TotalWin"
                        />
                      </div>
                    </b-card>
                  </b-col> -->
                </b-row>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
        <b-col
          v-if="((LottoHead && !LottoSubList) || LottoSubHead) && RoundID"
          lg="12"
        >
          <b-overlay
            :show="ShowLog"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-tabs
              pills
              align="center"
              style="background-color: #fff;"
              class="p-1"
            >
              <b-tab
                title="รายการแทงทั้งหมด"
                active
              >
                <b-card no-body>
                  <b-card-body>
                    <div class="p-1">
                      <h3>จำนวนแทงทั้งหมด</h3>
                    </div>
                  </b-card-body>
                  <b-table
                    small
                    striped
                    hover
                    responsive
                    class="position-relative items-center"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                  >
                    <template #cell(index)="data">
                      {{ perPage * (currentPage - 1) + (data.index + 1) }}
                    </template>
                    <template #cell(LottoSubHead)="data">
                      {{
                        data.item.LottoSubHead
                          ? NameLottoSubHead(
                            data.item.LottoHead,
                            data.item.LottoSubHead,
                          )
                          : '-'
                      }}
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        :variant="
                          data.item.status === 'confirm' ? 'success' : 'danger'
                        "
                        class="badge-glow"
                      >
                        {{ data.item.status === 'confirm' ? 'ยืนยัน' : 'ยกเลิก' }}
                      </b-badge>
                    </template>
                    <template #cell(status_result)="data">
                      <b-badge
                        :variant="
                          data.item.status_result === 'win'
                            ? 'success'
                            : data.item.status_result === 'waiting' && data.item.status === 'confirm'
                              ? 'info'
                              : data.item.status_result === 'processing'
                                ? 'warning'
                                : 'danger'
                        "
                        class="badge-glow"
                      >
                        {{
                          data.item.status_result === 'win'
                            ? 'ถูกรางวัล'
                            : data.item.status_result === 'waiting' && data.item.status !== 'confirm'
                              ? 'ยกเลิกโพย'
                              : data.item.status_result === 'waiting' && data.item.status === 'confirm'
                                ? 'รอผล'
                                : data.item.status_result === 'processing'
                                  ? 'กำลังตรวจ'
                                  : 'ไม่ถูกรางวัล'
                        }}
                      </b-badge>
                    </template>

                  </b-table>

                  <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                    <!-- page length -->
                    <b-form-group
                      label="Per Page"
                      label-cols="8"
                      label-align="left"
                      label-size="sm"
                      label-for="sortBySelect"
                      class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
                    >
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        inline
                        :options="pageOptions"
                        @change="GetData(RoundID)"
                      />
                    </b-form-group>

                    <!-- pagination -->
                    <div>
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                        @input="GetData(RoundID)"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </b-card-body>
                </b-card>

              </b-tab>

              <b-tab
                title="รายการที่ถูกรางวัล"
              >
                <b-card no-body>
                  <b-card-body>
                    <div class="p-1">
                      <h3>จำนวนให้รางวัล</h3>
                    </div>
                  </b-card-body>
                  <b-table
                    small
                    striped
                    hover
                    responsive
                    class="position-relative items-center"
                    :per-page="perPage"
                    :items="itemsLog"
                    :fields="fieldsLog"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                  >
                    <template #cell(index)="data">
                      {{ perPage * (currentPageLog - 1) + (data.index + 1) }}
                    </template>
                    <template #cell(LottoSubHead)="data">
                      {{
                        data.item.LottoSubHead
                          ? NameLottoSubHead(
                            data.item.LottoHead,
                            data.item.LottoSubHead,
                          )
                          : '-'
                      }}
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        :variant="
                          data.item.status === 'success' ? 'success' : 'danger'
                        "
                        class="badge-glow"
                      >
                        {{ data.item.status === 'success' ? 'สำเร็จ' : 'ไม่สำเร็จ' }}
                      </b-badge>
                    </template>

                  </b-table>

                  <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                    <!-- page length -->
                    <b-form-group
                      label="Per Page"
                      label-cols="8"
                      label-align="left"
                      label-size="sm"
                      label-for="sortBySelect"
                      class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
                    >
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        inline
                        :options="pageOptions"
                        @change="GetDataLog(RoundID)"
                      />
                    </b-form-group>

                    <!-- pagination -->
                    <div>
                      <b-pagination
                        v-model="currentPageLog"
                        :total-rows="totalRowsLog"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                        @input="GetDataLog(RoundID)"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </b-card-body>
                </b-card>

              </b-tab>

            </b-tabs>
          </b-overlay>
        </b-col>

      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  // BButton,
  BPagination,
  BTable,
  // BInputGroupPrepend,
  BFormSelect,
  BProgress,
  BBadge,
  BTabs,
  BTab,
  BOverlay,
  BIconController,

} from 'bootstrap-vue'
// import moment from 'moment-timezone'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment-timezone'
// import {
//   required, integer, between, length,
// } from '@validations'

export default {
  components: {
    vSelect,
    // BInputGroupPrepend,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BTable,
    ValidationObserver,
    BProgress,
    BBadge,
    BTabs,
    BTab,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      ShowRound: false,
      ShowResult: false,
      ShowLog: false,
      totalRows: 1,
      currentPage: 1,
      items: [],
      totalRowsLog: 1,
      currentPageLog: 1,
      itemsLog: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        {
          key: 'status',
          label: 'สถานะแทง',
        }, // confirm , cancel
        {
          key: 'status_result',
          label: 'สถานะออกหวย',
        },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // {
        //   key: 'LottoHead',
        //   label: 'หวย',
        //   formatter: value => this.NameLottoHead(value) || '-',
        // },
        // { key: 'LottoSubHead', label: 'ประเภทหวย' },
        { key: 'bet', label: 'เลข' },
        { key: 'name_type', label: 'ประเภท' },
        { key: 'amount', label: 'ยอด', formatter: value => this.Commas(value.toFixed(2)) },
        { key: 'win_rate', label: 'จ่าย' },
        // {
        //   key: 'startbalance',
        //   label: 'ราคาก่อนแทง',
        //   formatter: value => this.Commas(value),
        // },
        {
          key: 'endbalance',
          label: 'เครดิตหลังแทง',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      fieldsLog: [
        { key: 'index', label: 'ลำดับที่' },
        {
          key: 'status',
          label: 'สถานะให้รางวัล',
        }, // confirm , cancel
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'bet', label: 'แทงเลข' },
        { key: 'result_bet', label: 'เลขที่ออก' },
        { key: 'name_type', label: 'ประเภท' },
        { key: 'amount', label: 'ยอด', formatter: value => this.Commas(value.toFixed(2)) },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'reward', label: 'ผลรางวัล', formatter: value => this.Commas(value.toFixed(2)) },
        {
          key: 'startbalance',
          label: 'เครดิตก่อนให้รางวัล',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'endbalance',
          label: 'เครดิตหลังให้รางวัล',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'created_at',
          label: 'เวลาให้รางวัล',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      ResultData: {},
      RoundData: [],
      RoundID: null,
      Interval: null,
      IntervalTotalPro: null,
      IntervalTotalLog: null,
      TotalBet: 0,
      TotalPro: 0,
      TotalReward: 0,
      TotalLog: 0,
      TotalWin: 0,
      TotalLose: 0,
      SumAmountALl: 0,
      SumAmountWin: 0,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  async created() {
    this.LottoList = await this.GetSublist()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.totalRowsLog = this.items.length
    // this.Get_userlist()
    // this.getSMS()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    LottoStatus(Head, Sub, Val) {
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
          if (IndexSub >= 0) {
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    async SelectType() {
      this.ClearValue()
      this.RoundID = null
      this.RoundData = []
      if (this.LottoHead) {
        this.ShowRound = true
        await this.GetRound()
        this.ShowRound = false
      }
    },
    async SelectRound() {
      this.ClearValue()
      this.ShowResult = true
      await this.GetResult(this.RoundID)
      this.ShowResult = false
      await this.GetData(this.RoundID)
      await this.GetDataLog(this.RoundID)
      this.IntervalGetResult()
    },
    async IntervalGetResult() {
      this.Interval = setInterval(() => {
        this.GetResult(this.RoundID)
        if (this.ResultData.status === 'success') {
          clearInterval(this.Interval)
        }
      }, 30000)
    },
    async GetResult(ID) {
      const params = {
        ID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'result/ShowByRoundID',
          { params },
        )
        if (ResData.success) {
          this.ResultData = ResData.mes
          this.SumAmountALl = ResData.SumAmount
          this.SumAmountWin = ResData.SumAmountWin
          if (this.ResultData.status === 'processing' && this.TotalBet === 0) {
            this.TotalBet = await this.GetCountBet(this.ResultData.RoundID, 'total')
            this.TotalPro = (await this.GetCountBet(this.ResultData.RoundID, 'processing') - this.TotalBet) * -1
            this.IntervalGetCountPro()
          } else if (this.ResultData.status !== 'processing' && this.TotalBet > 0) {
            console.log('Stop')
            clearInterval(this.IntervalTotalPro)
          }
          if (this.ResultData.status === 'reward' && this.TotalLog === 0) {
            this.TotalReward = Object.keys(this.ResultData.Total).reduce((acc, ele) => acc + this.ResultData.Total[ele], 0)
            this.TotalWin = Object.keys(this.ResultData.WinTotal).reduce((acc, ele) => acc + this.ResultData.WinTotal[ele], 0)
            this.TotalLose = Object.keys(this.ResultData.LoseTotal).reduce((acc, ele) => acc + this.ResultData.LoseTotal[ele], 0)
            // eslint-disable-next-line no-underscore-dangle
            this.TotalLog = await this.GetCountLog(this.ResultData._id)
            this.IntervalGetCountLog()
          } else if (this.ResultData.status !== 'reward' && this.TotalLog > 0) {
            clearInterval(this.IntervalTotalLog)
          }

          if (this.ResultData.status === 'success' && this.TotalLog === 0) {
            this.TotalReward = Object.keys(this.ResultData.Total).reduce((acc, ele) => acc + this.ResultData.Total[ele], 0)
            this.TotalWin = Object.keys(this.ResultData.WinTotal).reduce((acc, ele) => acc + this.ResultData.WinTotal[ele], 0)
            this.TotalLose = Object.keys(this.ResultData.LoseTotal).reduce((acc, ele) => acc + this.ResultData.LoseTotal[ele], 0)
            // eslint-disable-next-line no-underscore-dangle
            this.TotalLog = await this.GetCountLog(this.ResultData._id)
          }
        }
      } catch (e) {
        console.log(e)
        // clearInterval(this.Interval)
        clearInterval(this.IntervalTotalPro)
        clearInterval(this.IntervalTotalLog)
      }
    },
    async GetRound() {
      const params = {
        LottoHead: this.LottoHead,
        LottoSubHead: this.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'round/ShowByHeadAndSubAll',
          { params },
        )
        if (ResData.success) {
          this.RoundData = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async IntervalGetCountLog() {
      this.IntervalTotalLog = setInterval(async () => {
        // eslint-disable-next-line no-underscore-dangle
        this.TotalLog = await this.GetCountLog(this.ResultData._id)
        if (this.TotalLog === this.TotalWin) {
          clearInterval(this.Interval)
          this.GetResult(this.RoundID)
          if (this.ResultData.status === 'success') {
            clearInterval(this.Interval)
          }
        }
      }, 5000)
    },
    async IntervalGetCountPro() {
      this.IntervalTotalPro = setInterval(async () => {
        this.TotalPro = (await this.GetCountBet(this.ResultData.RoundID, 'processing') - this.TotalBet) * -1
        if (this.TotalPro === this.TotalBet) {
          this.GetResult(this.RoundID)
        }
      }, 5000)
    },
    // eslint-disable-next-line consistent-return
    async GetCountBet(ID, Type) {
      const params = {
        ID,
        Type,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'result/count/betbystatus',
          { params },
        )
        if (ResData.success) {
          return ResData.mes
        }
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    // eslint-disable-next-line consistent-return
    async GetCountLog(ID) {
      const params = {
        ID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'reward/count/detailbyresult',
          { params },
        )
        if (ResData.success) {
          return ResData.mes
        }
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    NameLottoHead(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      return this.LottoList[Index].text
    },
    NameLottoSubHead(Head, Sub) {
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
        if (IndexSub >= 0) {
          return this.LottoList[IndexHead].SubList[IndexSub].text
        }
      }
      return '-'
    },
    FindSublist(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      this.LottoSubList = this.LottoList[Index].SubList
    },
    ClearValue() {
      this.TotalPro = 0
      this.TotalBet = 0
      this.TotalReward = 0
      this.TotalLog = 0
      this.ResultData = {}
      clearInterval(this.Interval)
      clearInterval(this.IntervalTotalPro)
      clearInterval(this.IntervalTotalLog)
    },
    GetData(ID) {
      // const index = 0
      this.ShowLog = true
      const params = {
        ID,
        page: this.currentPage,
        perpage: this.perPage,
      }
      this.$http
        .get(
          'history/byround',
          { params },
        )
        .then(response => {
          this.onFiltered(response.data.mes)
          this.ShowLog = false
        })
        .catch(error => {
          console.log(error)
          this.ShowLog = false
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.Data
      this.totalRows = filteredItems.total
    },
    GetDataLog(ID) {
      // const index = 0
      this.ShowLog = true
      const params = {
        ID,
        page: this.currentPageLog,
        perpage: this.perPage,
      }
      this.$http
        .get(
          'reward/byround',
          { params },
        )
        .then(response => {
          this.onFilteredLog(response.data.mes)
          this.ShowLog = false
        })
        .catch(error => {
          console.log(error)
          this.ShowLog = false
        })
    },
    onFilteredLog(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.itemsLog = filteredItems.Data
      this.totalRowsLog = filteredItems.total
    },
    Calpercen(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue).toFixed(2)
      // return (100 * partialValue) / totalValue
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
